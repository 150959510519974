import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../../components/Layout'
import { SeoData } from '../../types/Seo'
import { t } from '../../i18n'
import Landing4x4 from '../../../specific/static/landing-4x4'
import route from '../../utils/route'
import styles from '../landing.module.scss'
import { useSelectedMenu } from '../../../specific/helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../../specific/constants/menu'

interface Props {
  data: {
    seoData: SeoData
  }
}
export default function Index({ data }: Props) {
  useSelectedMenu(MenuPrincipal.NEUMATICOS)
  return (
    <Layout
      selector={false}
      seoData={data.seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
          link: route('neumaticos'),
        },
        {
          display: t('bread_crumb.4x4'),
        },
      ]}>
      <Landing4x4 styles={styles} data={data} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query landing4x4Query($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
    banners_ca_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/4x4/banners/ca/desktop/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/4x4/banners/ca/mobile/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/4x4/banners/es/desktop/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/4x4/banners/es/mobile/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    images_ca_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/4x4/imagenes/ca/desktop/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    images_ca_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/4x4/imagenes/ca/mobile/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    images_es_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/4x4/imagenes/es/desktop/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    images_es_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/4x4/imagenes/es/mobile/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_pt_desktop: allS3Asset(
      filter: { Key: { glob: "pneus/pneus-carro-4x4-suv.jpg" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_pt_mobile: allS3Asset(
      filter: { Key: { glob: "pneus/pneus-4x4-suv.jpg" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    images_pt_desktop: allS3Asset(
      filter: {
        Key: { glob: "pneus/pneus-para-4x4-suv-automovel-montanha.jpg" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    images_pt_mobile: allS3Asset(
      filter: { Key: { glob: "pneus/pneus-montanha-4x4.jpg" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`
