import { navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import SelectorContainer from '../../../src/common/components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../../src/common/components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../../src/common/components/FunnelSelector/SelectorSteps'
import ResponsiveImages from '../../../src/common/components/ResponsiveImages/ResponsiveImages'
import SearchNeumaticos from '../../../src/common/components/searchNeumaticosCardHome/index'
import ResponsiveSliderBanner from '../../../src/common/components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../../src/common/components/Title'
import { reset } from '../../../src/common/context/actions/selectorActions'
import AppContext, { IAppState } from '../../../src/common/context/context'
import { selectSkipInfo } from '../../../src/common/context/selectors/selectorSelectors'
import { locale } from '../../../src/common/i18n'
import { temporadaMapping } from '../../../src/specific/helpers/gtmReporter'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import { SeoData } from '../../../src/common/types/Seo'
import route from '../../../src/common/utils/route'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const Landing4x4Pt = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image_mobile] = getImages(
    data[`images_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`images_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )

  return (
    <div className={styles.container}>
      <Title text="Pneus para 4x4" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
        Um 4x4 tem pneus que oferecem prestações e um design adequados para conseguir uma aderência superior em todos os tipos de terreno. O aparecimento de modelos de carros mais volumosos durante a última década, juntamente com a moda dos SUV, fez com que os pneus 4x4 se tenham tornado muito populares.
        </p>
        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_landings'}
        />
        <section className={styles.section}>
          <h2>Como saber que rodas escolher para um carro 4x4?</h2>
          <div>
            <p>
            A primeira coisa a ter em conta é que existem 3 tipos diferentes de pneus para carros 4x4. Existem opções concebidas e construídas exclusivamente para circular em estradas Off-Road cheias de pedras, lama e areia... Também existem modelos de pneus focados unicamente para circular em estradas alcatroadas e opções mistas.
            </p>
            <ul>
              <li>
                <strong>Pneus de estrada: </strong> São concebidos para serem utilizados principalmente em asfalto (80%) e servem para circular pontualmente pelo campo. Este modelo de pneu é ideal para os proprietários de um carro 4x4 ou SUV que circulem habitualmente em cidade e façam pontualmente viagens para zonas com caminhos de terra.
              </li>
              <li>
                <strong>Pneus mistos:  </strong>Foram concebidos para uma utilização muito versátil, 50% campo e 50% zonas urbanas. Este pneu é ideal para pessoas que circulam regularmente por zonas Off-Road e asfaltadas indistintamente. Oferecem uma aderência excecional e uma incrível facilidade de utilização em lama ou areia.
              </li>
              <li>
                <strong>Pneus Off-Road: </strong>Concebidos para uso intensivo em 80% por zonas de montanha. São um tipo de pneu muito resistente, preparado para suportar a pressão dos terrenos mais exigentes. Adaptam-se perfeitamente a zonas com terra, pedras, lama ou areia. O desenho da banda de rodagem tem um desenho com grandes ranhuras que permitem uma grande aderência.
              </li>
            </ul>
          </div>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>
            Como saber de que tipo de pneu todo-o-terreno preciso para o meu carro 4x4?
          </h2>
          <div>
            <p>
            Antes de mais, recomendamos-lhe que faça o exercício de contagem da percentagem de utilização que dá aos seus 4x4.
            </p>
            <p>
            Pouco lhe podemos aconselhar que ainda não saiba quando conduz por zonas urbanas, mas em terreno Off-Road podemos, sim, dar-lhe alguns conselhos úteis:
            </p>
            <ul>
              <li>
              Circule a velocidades moderadas. Em zonas Off-Road, os imprevistos são frequentes, por isso, deve-se tomar precauções extremas. Quanto maior for a velocidade, mais complicado é reagir a tempo e, portanto, a probabilidade de sofrer um acidente aumenta exponencialmente.
              </li>
              <li>
              Circule a velocidades moderadas. Em zonas Off-Road, os imprevistos são frequentes, por isso, deve-se tomar precauções extremas. Quanto maior for a velocidade, mais complicado é reagir a tempo e, portanto, a probabilidade de sofrer um acidente aumenta exponencialmente.
              </li>
              <li>
              Tenha cuidado com o acelerador. Sobretudo, em zonas com areia ou lama. A tração neste tipo de terreno é menos eficiente e se cometer o erro de acelerar abruptamente aumentará a hipótese de ficar preso.
              </li>
            </ul>
            <p>
            Se precisar de mais informações sobre Pneus Off-Road, solicite uma marcação prévia na sua oficina Rodi Motor mais próxima e os nossos especialistas em mecânica ajudá-lo-ão a resolver todas as suas dúvidas.
            </p>
          </div>
        </section>
      </article>
    </div>
  )
}

interface SelectorProps {
  styles: { [k: string]: string }
  dispatch: (action: object) => void
  skipInfo: InSkipInfo
}
const ConnectedSelector = ({ dispatch, styles, skipInfo }: SelectorProps) => {
  const { selectorState } = useContext(AppContext)
  // Initialize selector values
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('landing-4x4'),
        temporada: { selectable: false, value: 'verano' },
        tipoVehiculo: 2,
      })
    )
  }, [])

  return (
    <React.Fragment>
      <SearchNeumaticos
        gtmData={{
          action: '4x4',
          runflat: selectorState.runflat.value,
          tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        }}
      />

      <div className={styles.selector_container}>
        <SelectorContainer showMarcas={false} origin={route('landing-4x4')}>
          {({ ...props }) => (
            <SelectorForm
              showTemporada={false}
              fromModal={false}
              handleSubmit={() => {
                const next = findNext(Step.SELECT_MARCAS, 'desktop', skipInfo)
                navigate(next.routes.desktop)
              }}
              {...props}
            />
          )}
        </SelectorContainer>
      </div>
    </React.Fragment>
  )
}
const Selector = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(ConnectedSelector)

const Landing4x4 = ({ ...props }: Props) => <Landing4x4Pt {...props} />

export default Landing4x4
